@font-face {
  font-family: Satoshi;
  src: url(../public/Satoshi-Regular.otf);
}

@font-face {
  font-family: Satoshi-medium;
  src: url(../public/Satoshi-Medium.otf);
}

* {
  font-family: Satoshi;
}

.add-items-btn {
  padding: 10px 13px;
  border-radius: 3px;
  background: #F79421;
  color: #FFF;
  font-family: Satoshi;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.72px;
  border: none;
  margin-top: 10px;
}

.close-btn {
  background-color: #5c636a;
  padding: 10px 13px;
  border-radius: 3px;
  color: #FFF;
  font-family: Satoshi;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.72px;
  border: none;
  margin-top: 10px;
}